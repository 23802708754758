<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" pagination max-items="10" search :data="roles">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <template v-if="activeUserInfos.privileges.find(item => item === 'create')">
                  <vs-button class="mb-4 ml-5 md:mb-0" @click="$router.push('/apps/role/role-add')">Nouveau rôle</vs-button>
                </template>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>
                <vs-th sort-key="libelle">
                  Libelle
                </vs-th>
                <vs-th sort-key="privileges">
                  Privilèges
                </vs-th>
                <vs-th sort-key="status">
                 Status
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].libelle">
                    {{data[indextr].libelle}}
                  </vs-td>

                  <vs-td :data="data[indextr].privileges">
                    {{data[indextr].privileges.length}}
                  </vs-td>

                  <vs-td :data="data[indextr].status">
                    <template v-if="data[indextr].status == true">
                      <vs-chip transparent color="success">
                        active
                      </vs-chip>

                    </template>
                    <template v-else>
                      <vs-chip transparent color="danger">
                        inactive
                      </vs-chip>
                    </template>

                  </vs-td>


                  <vs-td :data="data[indextr]._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <template v-if="activeUserInfos.privileges.find(item => item === 'update')">
                        <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateRoleShowForm(data[indextr])" />
                      </template>
                      <template v-if="activeUserInfos.privileges.find(item => item === 'delete')">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                      </template>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: [],
      roleId: '',
      activeUserInfos: null
    }
  },
  computed: {
    roles () {
      return this.$store.state.role.roles
    }
  },
  methods: {
    updateRoleShowForm (data) {
      this.$router.push(`/apps/role/role-edit/${data._id}`)
    },
    confirmDeleteRecord (data) {
      this.roleId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer',
        text: `Vous allez supprimer le role "${data.libelle}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('role/deleteRole', this.roleId)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => {
          console.log(err)
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'supprimer',
        text: 'success'
      })
    },
    getRoles () {
      this.$store.dispatch('role/getRoles')
        .then((resp) => {
          console.log(resp)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      console.log(this.activeUserInfos)

    }
  },
  created () {
    this.getRoles()
    this.activeUserInfo()
  }
}
</script>
